@font-face {
  font-family: Questrial;
  src: url("./assets/font/Questrial/Questrial-Regular.ttf");
}

* {
  font-family: Questrial;
}

.vertical-center {
  display: flex;
  align-items: center;
  min-height: 100vh; /* Mengatur tinggi minimum wadah menjadi setidaknya tinggi viewport */
  /* Gaya lainnya sesuai kebutuhan */
}

.center-content {
  margin: 0 auto; /* Mengatur margin horizontal otomatis untuk konten di tengah */
  /* Gaya lainnya sesuai kebutuhan */
}

.glow-text {
  color: gray;
  filter: drop-shadow(
    0px 0px 3px rgba(255, 255, 255, 0.3)
  ); /* Menambahkan efek shadow untuk menciptakan efek bersinar */
}

.glow-logo {
  width: 50%;
  height: auto;
  animation: blink 3s infinite;
  /* filter: drop-shadow(
    0px 0px 10px rgba(255, 255, 255, 0.7)
  );  */
}

@keyframes blink {
  0%,
  100% {
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));
  }

  50% {
    filter: drop-shadow(
      0px 0px 20px rgba(255, 255, 255, 1)
    ); /* Efek shadow diperbesar saat animasi berada pada 50% */
  }
}

.glow-on-hover {
  color: gray;
}

.glow-on-hover:hover {
  color: white;
  cursor: pointer;
  font-weight: bold;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.7));
}

.Content {
  padding: 100px 0; /* Adjust padding to create space for the fixed header */
}

.ContentContainer {
  /* padding: 20px; */
}

.cardsHover {
  transition: all 0.2s ease;
  cursor: pointer;
}

.cardsHover:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  transform: scale(1.1);
}
